import React from 'react';
import { Card } from 'reactstrap';
import { AccordionBody } from './AccordionBody';
import { AccordionHeader } from './AccordionHeader';

export const AccordionItem = ({ children, isOpen, onClick }) => (
  <Card className="accordion">
    {React.Children.map(children, (child) => {
      if (child.type === AccordionHeader) {
        return React.cloneElement(child, { onClick, isOpen });
      }

      if (child.type === AccordionBody) {
        return React.cloneElement(child, { isOpen });
      }

      return null;
    })}
  </Card>
);
