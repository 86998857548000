import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layouts/Layout';
import { Accordion } from '../components/Common/Accordion/Accordion';
import SEO from '../components/Seo';
import { Container, Card } from 'reactstrap';
import { faqTypes } from '../pagesGeneration/data/faqTypes';

const Faq = ({ data }) => {
  const faqAllTypes = data?.allStrapiFaqs.nodes;
  const faqByTypes = faqAllTypes.reduce(function (memo, x) {
    if (!memo[x.nodeType]) {
      memo[x.nodeType] = [];
    }
    memo[x.nodeType].push(x);
    return memo;
  }, {});

  return (
    <Layout navColor="white" margined={true}>
      <SEO title={`Често задавани въпроси`} description={`Често задавани въпроси`} />
      <Container fluid="md" className="py-5">
        <h1 className="mb-4">Често задавани въпроси</h1>
        <div>
          {Object.keys(faqByTypes).map((key) => {
            return (
              <section key={key} className="pt-4 px-3 my-3 popular_areas_section">
                <h3>{faqTypes.find((type) => type.type == key).label}</h3>
                <Accordion>
                  {faqByTypes[key].map((faq, i) => {
                    return (
                      <Accordion.Item key={`faq-id-${i}`}>
                        <Accordion.Header>{faq.question}</Accordion.Header>
                        <Accordion.Body>{faq.answer}</Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </section>
            );
          })}
        </div>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  {
    allStrapiFaqs(sort: { fields: order_index, order: ASC }) {
      nodes {
        nodeType
        strapiId
        question
        answer
      }
    }
  }
`;

export default Faq;
