import React from 'react';
import { CardHeader, Button } from 'reactstrap';
import { RiArrowDownSLine } from '@react-icons/all-files/ri/RiArrowDownSLine';
import { RiArrowUpSLine } from '@react-icons/all-files/ri/RiArrowUpSLine';

export const AccordionHeader = ({ children, onClick, isOpen }) => (
  <CardHeader className="accordion-header" onClick={onClick}>
    <h5 className="mb-0">
      <Button color="link">{children}</Button>
      {isOpen ? <RiArrowUpSLine size={26} color="primary" /> : <RiArrowDownSLine size={26} />}
    </h5>
  </CardHeader>
);
