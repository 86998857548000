import React from 'react';
import { CardBody, Collapse } from 'reactstrap';

export const AccordionBody = ({ children, isOpen }) => (
  <Collapse isOpen={isOpen}>
    <CardBody>
      {typeof children === 'string' ? (
        <div dangerouslySetInnerHTML={{ __html: children }}></div>
      ) : (
        children
      )}
    </CardBody>
  </Collapse>
);
