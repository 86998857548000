import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AccordionItem } from './AccordionItem';
import { AccordionBody } from './AccordionBody';
import { AccordionHeader } from './AccordionHeader';

export const Accordion = ({ children }) => {
  const [open, setOpen] = useState(false);

  const toggleSection = (index) => {
    setOpen(({ open }) => ({
      open: index === open ? undefined : index,
    }));
  };

  return (
    <div>
      {React.Children.map(children, (child, index) => {
        if (child.type !== AccordionItem) return null;
        return React.cloneElement(child, {
          isOpen: child.props.open || open.open === index,
          onClick: () => toggleSection(index),
        });
      })}
    </div>
  );
};

Accordion.propTypes = {
  open: PropTypes.number,
};

Accordion.Item = AccordionItem;
Accordion.Header = AccordionHeader;
Accordion.Body = AccordionBody;
